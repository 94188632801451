var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "form-wrapper",
      attrs: {
        "auto-complete": "on",
        model: _vm.form,
        rules: _vm.rules,
        "label-width": "160px",
      },
    },
    [
      !_vm.estimate && !_vm.form.is_template
        ? _c(
            "el-form-item",
            { attrs: { label: "Project" } },
            [
              _c("project-dropdown", {
                model: {
                  value: _vm.selectedProjectId,
                  callback: function ($$v) {
                    _vm.selectedProjectId = $$v
                  },
                  expression: "selectedProjectId",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.form.is_template
        ? _c(
            "el-form-item",
            { attrs: { label: "Reference" } },
            [
              _c("el-input", {
                attrs: {
                  name: "ref",
                  type: "text",
                  "auto-complete": "off",
                  disabled: "",
                },
                model: {
                  value: _vm.form.ref,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ref", $$v)
                  },
                  expression: "form.ref",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("el-form-item", [
        _c(
          "div",
          { staticClass: "type-selector" },
          [
            _c(
              "el-button",
              {
                staticClass: "explore",
                class: {
                  selected: _vm.form.type === _vm.ESTIMATE_TYPES.EXPLORE,
                },
                attrs: { disabled: !!_vm.estimate },
                on: {
                  click: function ($event) {
                    _vm.form.type = _vm.ESTIMATE_TYPES.EXPLORE
                  },
                },
              },
              [_vm._v(" Explore ")]
            ),
            _c(
              "el-button",
              {
                staticClass: "create",
                class: {
                  selected: _vm.form.type === _vm.ESTIMATE_TYPES.CREATE,
                },
                attrs: { disabled: !!_vm.estimate },
                on: {
                  click: function ($event) {
                    _vm.form.type = _vm.ESTIMATE_TYPES.CREATE
                  },
                },
              },
              [_vm._v(" Create ")]
            ),
          ],
          1
        ),
      ]),
      !_vm.is_editing && !_vm.groups.length
        ? _c(
            "el-form-item",
            { attrs: { label: "Template" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: "Start from scratch",
                    "value-key": "id",
                    disabled:
                      _vm.is_estimate_completed || !!_vm.change_request_link,
                  },
                  model: {
                    value: _vm.template,
                    callback: function ($$v) {
                      _vm.template = $$v
                    },
                    expression: "template",
                  },
                },
                _vm._l(_vm.filtered_templates, function (t) {
                  return _c("el-option", {
                    key: t.id,
                    attrs: { label: t.title, value: t },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("hr"),
      _c(
        "el-form-item",
        { attrs: { label: "Title", prop: "title" } },
        [
          _c("el-input", {
            ref: "title",
            attrs: {
              name: "title",
              type: "text",
              "auto-complete": "off",
              disabled: _vm.is_estimate_locked,
            },
            model: {
              value: _vm.form.title,
              callback: function ($$v) {
                _vm.$set(_vm.form, "title", $$v)
              },
              expression: "form.title",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Description", prop: "content" } },
        [
          _c("el-input", {
            attrs: { disabled: _vm.is_estimate_locked },
            model: {
              value: _vm.form.description,
              callback: function ($$v) {
                _vm.$set(_vm.form, "description", $$v)
              },
              expression: "form.description",
            },
          }),
        ],
        1
      ),
      !_vm.form.is_template
        ? _c(
            "el-form-item",
            { attrs: { label: "Client", prop: "client" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    placeholder: "No client selected",
                    "value-key": "id",
                    disabled: _vm.is_estimate_locked,
                    clearable: "",
                  },
                  model: {
                    value: _vm.form.client,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "client", $$v)
                    },
                    expression: "form.client",
                  },
                },
                [
                  _c(
                    "el-option-group",
                    { key: "no-client", attrs: { label: "" } },
                    [
                      _c(
                        "el-option",
                        {
                          key: "no-client",
                          attrs: { label: "No client", value: null },
                        },
                        [
                          _c("span", [_vm._v("No client")]),
                          _c("span", { staticClass: "option-subtitle" }, [
                            _vm._v(" Select one later on "),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-option-group",
                    { key: "clients", attrs: { label: "Existing clients:" } },
                    _vm._l(_vm.clients, function (c) {
                      return _c("el-option", {
                        key: c.id,
                        attrs: { label: c.name, value: `clients/${c.id}` },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.estimate && !_vm.is_template
        ? _c(
            "el-form-item",
            { attrs: { label: "New template" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.form.is_template,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "is_template", $$v)
                  },
                  expression: "form.is_template",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("hr"),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Unit" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: _vm.is_estimate_locked },
                      model: {
                        value: _vm.form.unit,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "unit", $$v)
                        },
                        expression: "form.unit",
                      },
                    },
                    _vm._l(_vm.unit_types, function (u) {
                      return _c("el-option", {
                        key: u.value,
                        attrs: { label: u.label, value: u.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { sm: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Hours / day" } },
                [
                  _c("el-input-number", {
                    staticClass: "small",
                    attrs: { disabled: _vm.is_estimate_locked },
                    model: {
                      value: _vm.form.hours_per_day,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "hours_per_day", $$v)
                      },
                      expression: "form.hours_per_day",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Rate" } },
        [
          _c("span", { staticClass: "small-label" }, [_vm._v("ex GST")]),
          _c(
            "money",
            _vm._b(
              {
                staticClass: "el-input__inner",
                attrs: { disabled: _vm.is_estimate_locked },
                model: {
                  value: _vm.form.rate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "rate", $$v)
                  },
                  expression: "form.rate",
                },
              },
              "money",
              _vm.money,
              false
            )
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row" },
        [
          _vm.is_editing
            ? _c(
                "el-button",
                {
                  attrs: { disabled: _vm.loading },
                  on: { click: _vm.handleCancel },
                },
                [
                  _vm.is_estimate_locked
                    ? _c("span", [_vm._v("Close")])
                    : _c("span", [_vm._v("Cancel")]),
                ]
              )
            : _vm._e(),
          !_vm.is_estimate_locked
            ? _c(
                "el-button",
                {
                  attrs: { disabled: _vm.loading },
                  on: { click: _vm.handleSubmit },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.estimate && _vm.estimate.id ? "Save" : "Create"
                      ) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.change_request_link
        ? [
            _c("hr"),
            _c("div", { staticClass: "cr-link" }, [
              _c("span", [
                _vm._v(" This estimate with be linked to change request "),
                _c("strong", [_vm._v(_vm._s(_vm.change_request_link))]),
              ]),
            ]),
          ]
        : _vm._e(),
      _vm.groups.length
        ? [
            _c("hr"),
            _c("div", { staticClass: "group-summary" }, [
              _c("span", [
                _vm._v(
                  " The following groups will be copied into the new estimate from "
                ),
                _c("strong", [_vm._v(" " + _vm._s(_vm.source_estimate) + " ")]),
                _vm.clone_rule === "included"
                  ? _c("span", [_vm._v(" (included items only) ")])
                  : _vm._e(),
                _vm.clone_rule === "excluded"
                  ? _c("span", [_vm._v(" (excluded items only) ")])
                  : _vm._e(),
                _vm._v(" : "),
              ]),
              _c(
                "ul",
                _vm._l(_vm.groups, function (group) {
                  return _c("li", { key: group.id }, [
                    _vm._v(" " + _vm._s(group.title) + " "),
                  ])
                }),
                0
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }